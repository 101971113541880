<template>
    <div class="login">
        <div class="form-box">
            <h2 class="title">应用平台</h2>
            <el-form ref="form" :model="form" :rules="rules" class="form">
                <el-form-item prop="account">
                    <el-input prefix-icon="el-icon-user" v-model="form.account" placeholder="用户名"> </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input type="password" prefix-icon="el-icon-lock" v-model="form.password" @keydown.native="keydown" placeholder="密码">
                        <!-- <button class="pwd" type="button" slot="suffix" @click="open = !open" style="margin-right:5px;" ><i :class="pswSeen"></i></button> -->
                    </el-input>
                </el-form-item>
                <el-form-item prop="captcha" class="code-container" v-if="enabled">
                    <el-input placeholder="验证码" prefix-icon="el-icon-lock" v-model="form.captcha" @keydown.native="keydown"></el-input>
                    <img :src="codeURL" alt="" @click="refreshCode">
                </el-form-item>
                <el-form-item>
                    <el-button class="btn-login" @click="handleLogin">登录</el-button>
                </el-form-item>
            </el-form>
        </div>

        
    </div>
</template>

<script>
import { getCode } from '@/api/login.js';
import { blobToDataURI } from '@/utils/file.js';

export default {
    name: "Login",
    data() {
        return {
            form: {
                account: "",
                password: "",
                captcha: '',
            },
            openCheck: true,
            rules: {
                account: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
                captcha: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    }
                ]
            },
            open: true,
            codeURL: '',
        };
    },
    created(){
        this.refreshCode();
        // if(process.env.NODE_ENV === 'development'){
        //     this.form.account = 'admin';
        //     this.form.password = 'whrp1314';
        // }
    },
    computed: {
        enabled(){
            return this.$store.getters.configMap['whrp.user.login.captcha.enabled'];
        }
    },
    methods: {
        refreshCode(){
            if(this.enabled){
                getCode().then(res => {
                    blobToDataURI(res).then(dataurl => {
                        this.codeURL = dataurl;
                    })
                })

            }
        },
        keydown(event){
            if(event.keyCode === 13){
                this.handleLogin();
            }
        },
        handleLogin() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.$store.dispatch('user/login',this.form).then(() => {
                        const redirectUrl = this.$route.query.redirectUrl;
                        if(redirectUrl){
                            this.$router.push(redirectUrl);
                        }else{
                            this.$router.push({ name: "home" });
                        }
                    }).catch(err => {
                        console.log(err);
                        this.refreshCode();
                    }).finally(() => {
                        this.loading = false;
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        toggleSave() {
            this.$store.commit("user/TOGGLE");
        },
    },
};
</script>

<style lang="less" scoped>
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: skyblue;

    .form-box {
        width: 479px;
        height: 427px;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .title {
            margin-bottom: 55px;
            text-align: center;
            font-size: 24px;
            letter-spacing: 3px;
            // color: #fff;
        }

        .form {
            width: 370px;
            margin: 0 auto;

            .btn-login {
                margin-top: 35px;
                width: 100%;
                color: #fff;
                background: #08ebfe;
                border: none;
            }
            .code-container{
                /deep/ .el-input{
                    margin-right: 10px;
                }
                /deep/ .el-form-item__content{
                    display: flex;
                    img{
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
</style>

